// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  client: 'chinaair',
  production: false,
  stripePublishableKey: 'pk_test_AKFzXNrTAU4SAL1NTUimhbl3',
  stripePublishableKeyTest : '%stripeTestClientId%',
  paypalClientId: 'ASWolWWmcWqVsTLFo4wdwlURxKGrXJYsWlWXoPzNzkza6apKHo_MkCb65jKoUpWornyGqqGUZTog2g7A',
  paypalClientIdTest: '%paypalTestClientId%',
  siteUrl: 'http://dev-chinaair-zh.co2analytics.com/',
  defaultLanguage: 'zh',
  minimumBaseCurrencyAmount: 0.75,
  defaultTabIndex: 1,
  logoImg: {
    name: 'sustainability-logo-round-resized.png',
    height: '110px',
    width: '105px'
  },
  txOrigins: [
    '*'
  ],
  rxOrigins: [
    '*'
  ],
  partnerConfig: {
    partnerKey: '12aefac5-6705-46a6-885a-eb9809c20e6c',
    domain: 'china-airlines.com'
  },
  apis: {
    domain: 'https://dev-srvc-calculators.co2analytics.com',
    fx: 'https://dev-srvc-ecomm.co2analytics.com/api/v1',
    fin: 'https://dev-srvc-ecomm.co2analytics.com/api/v1',
    version: 'v1.0.0'
  },
  bypassCode: '%bypassCode%',
  componentHierarchy: {
    headerComponents: [
      { templateName: 'calculatorHeaderComponent', componentName: 'CalculatorHeaderComponent' }
    ],
    featureComponents: [
      {
        name: 'start',
        seqn: '0',
        title: '快速偏置',
        theme: {
          fillColor: '#21536F',
          textClass: 'calculator-selector-text-light'
        },
        component: { templateName: 'startComponent', componentName: 'ClimateCareStartComponent' }
      },
      {
        name: 'flights',
        seqn: '1',
        title: '航班',
        theme: {
          fillColor: '#306E8F',
          textClass: 'calculator-selector-text-light'
        },
        component: { templateName: 'flightsComponent', componentName: 'ClimateCareFlightsComponent' }
      }
    ],
    global: [
      {
        component: { defaultFeatureIndex: 0 }
      },
      {
        component: { templateName: 'offsetProjectsComponent', componentName: 'OffsetProjectsComponent' }
      },
      {
        component: { templateName: 'cartComponent', componentName: 'CartComponent' }
      },
      {
        component: { templateName: 'customerDetailsComponent', componentName: 'CustomerDetailsComponent' }
      },
      {
        component: { templateName: 'payOffsetsComponent', componentName: 'PayOffsetsComponent' }
      },
      {
        component: { templateName: 'confirmationComponent', componentName: 'ConfirmationComponent' }
      }
    ],
    footerComponents: [
      { templateName: 'icroaFooterComponent', componentName: 'IcroaFooterComponent' }
    ]
  }
}

// For easier debugging in development mode, you can import the following file
// to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
//
// This import should be commented out in production mode because it will have a negative impact
// on performance if an error is thrown.
//
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
